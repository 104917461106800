import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: 10,
  },
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  detailStyle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: 0,
  },
  diagnostic: {
    marginRight: 10,
    marginBottom: 10,
  },
  updateStyle: {
    color: '#005abb',
    textDecorationLine: 'underline',
    textTransform: 'none',
    padding: 0,
    fontWeight: 'normal',
    marginRight: 5,
  },
  deleteStyle: {
    color: '#cd3a3a',
    textDecorationLine: 'underline',
    textTransform: 'none',
    padding: 0,
    fontWeight: 'normal',
  },

  textStyle: {
    whiteSpace: 'pre-line',
    textAlign: 'left',
  },
  gridVertical: {
    display: 'flex',
    flexDirection: 'column',
  },
  innerGrid: {
    marginBottom: 10,
  },
  divider: {
    marginBottom: 20,
  },
  accordion: {
    marginTop: 15,
    marginBottom: 15,
  },
  chip: {
    marginRight: 10,
    marginBottom: 10,
    padding: 5,
  },
  iconButton: {
    padding: 5,
    color: 'white',
    borderRadius: 50,
    cursor: 'pointer',
    marginRight: 5,
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  headerIconAndTitle: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    '& span': {
      fontSize: '0.7vw',
      fontWeight: 'bold',
    },
  },
}));

export default styles;
