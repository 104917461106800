import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionResult, Coordinate, MapNode } from '_lib/api';
import { LatLng, Map as LeafletMap } from 'leaflet';

import {
  ApplicationState,
  AppTheme,
  DialogType,
  MapElement,
  PointCount,
  PointSelectionParams,
  PointSelectionType,
  ToastTitle,
} from './types';

const center: LatLng = new LatLng(45.502983, 8.96494);

const initialState: ApplicationState = {
  currentLocale: null,
  activeDialog: null,
  pointSelections: {
    active: false,
    pointCount: PointCount.ONE,
    points: [],
    style: null,
  },
  basePoint: null,
  deletePoints: {
    active: false,
    pointCount: PointCount.TWO,
    direction: null,
    points: [],
    pointsNumberTo: [],
    pointsNumberFrom: [],
    style: null,
  },
  manageSelections: {
    active: false,
    pointCount: PointCount.ONE,
    direction: null,
    points: [],
    pointsNumberTo: [],
    pointsNumberFrom: [],
    style: null,
  },
  currentSelection: null,
  extraMapElements: {},
  extraTraceMapElements: {},
  activeLayers: {
    event: true,
    traffic: true,
    rsu: true,
    data: true,
  },
  countLayers: 0,
  toasts: [],
  centerCoordsPosition: {
    center,
  },
  eventCoordsPosition: {
    center,
  },
  ready: false,
  mapRef: null,
  zoom: 14,
  userLoggedIn: false,
  theme: AppTheme.LIGHT,
};

const applicationReducer = createSlice({
  name: 'application',
  initialState,
  reducers: {
    dialogClosed(state, _action: PayloadAction<void>) {
      state.activeDialog = null;
    },
    infoSelected(state, action: PayloadAction<{ type: DialogType; id: string }>) {
      state.activeDialog = {
        type: action.payload.type,
        id: action.payload.id,
        busy: false,
      };
    },
    pointSelectionStarted(state, action: PayloadAction<PointSelectionParams>) {
      state.currentSelection = action.payload.type;
      const rp =
        (action.payload.type !== PointSelectionType.DENM_REFERENCE_POINT &&
          state.extraMapElements[PointSelectionType.DENM_REFERENCE_POINT]) ||
        (action.payload.type !== PointSelectionType.IVIM_REFERENCE_POINT &&
          state.extraMapElements[PointSelectionType.IVIM_REFERENCE_POINT]);
      state.pointSelections = {
        active: true,
        pointCount: action.payload.pointCount,
        points: rp && state.currentSelection !== PointSelectionType.MEASUREMENT ? [rp[0].loc] : [],
        style: action.payload.style,
      };
    },
    pointSelectionAborted(state, _action: PayloadAction<void>) {
      state.currentSelection = null;
      state.pointSelections = {
        active: false,
        pointCount: PointCount.ONE,
        points: [],
        style: null,
      };
    },
    basePointSelected(state, action: PayloadAction<{ poi: MapNode; bound: string }>) {
      state.basePoint = action.payload;
    },
    changePointCount(state, action: PayloadAction<PointCount>) {
      state.pointSelections.pointCount = action.payload;
    },
    pointSelected(state, action: PayloadAction<Coordinate>) {
      if (!state.pointSelections.active) {
        return;
      }
      switch (state.pointSelections.pointCount) {
        case PointCount.ONE:
          state.pointSelections.points = [action.payload];
          break;
        case PointCount.TWO:
          state.pointSelections.points = [
            ...state.pointSelections.points.slice(0, 1),
            action.payload,
          ];
          break;
        case PointCount.TWENTYTHREE:
          state.pointSelections.points = [
            ...state.pointSelections.points.slice(0, 23),
            action.payload,
          ];
          break;
        case PointCount.FORTY:
          state.pointSelections.points = [
            ...state.pointSelections.points.slice(0, 40),
            action.payload,
          ];
          break;
        case PointCount.MANY:
          state.pointSelections.points.push(action.payload);
          break;
        default:
          break;
      }
    },
    setMapElement(state, action: PayloadAction<{ id: string; elements: MapElement[] }>) {
      state.extraMapElements[action.payload.id] = action.payload.elements;
    },
    changeMapElementStyle(
      state,
      action: PayloadAction<{ id: string; color: string; weight: number; className: string }>,
    ) {
      if (!state.extraMapElements[action.payload.id]) {
        return;
      }
      state.extraMapElements[action.payload.id].forEach((element) => {
        element.options.color = action.payload.color;
        element.options.weight = action.payload.weight;
        element.options.className = action.payload.className;
      });
    },
    setTraceMapElement(state, action: PayloadAction<{ id: string; elements: Coordinate[] }>) {
      state.extraTraceMapElements[action.payload.id] = action.payload.elements;
    },
    setMapRef(state, action: PayloadAction<LeafletMap>) {
      state.mapRef = action.payload;
    },
    removeMapElements(state, action: PayloadAction<string>) {
      if (action.payload === PointSelectionType.DENM_TRACES) {
        const denmTraces = PointSelectionType.DENM_TRACES;
        state.extraMapElements = Object.fromEntries(
          Object.entries(state.extraMapElements).filter(([key]) => !key.startsWith(denmTraces)),
        );
      }
      delete state.extraMapElements[action.payload];
    },
    removeAllMapElements(state, _action: PayloadAction) {
      state.extraMapElements = {};
    },
    removeMultiMapElements(state, action: PayloadAction<string[]>) {
      action.payload.forEach((type) => {
        delete state.extraMapElements[type];
      });
    },
    removeTraceMapElements(state, action: PayloadAction) {
      state.extraTraceMapElements = {};
    },
    toggleActiveLayer(state, action: PayloadAction<string>) {
      const id = action.payload;
      if (id in state.activeLayers) {
        state.activeLayers[id] = !state.activeLayers[id];
      } else {
        state.activeLayers[id] = true;
      }

      if (id in state.activeLayers) {
        state.countLayers = state.activeLayers[id] ? state.countLayers + 1 : state.countLayers - 1;
      }
    },
    updateActiveLayer(state, action: PayloadAction<{ layer: string; status: boolean }>) {
      const { layer, status } = action.payload;
      state.activeLayers[layer] = status;
      if (layer in state.activeLayers) {
        state.countLayers = state.activeLayers[layer]
          ? state.countLayers + 1
          : state.countLayers - 1;
      }
    },
    dialogRequestStarted(state, _action: PayloadAction<void>) {
      state.activeDialog.busy = true;
    },
    dialogRequestFinished(state, action: PayloadAction<ActionResult>) {
      if (state.activeDialog) {
        state.activeDialog.busy = false;
      }
      // If action was successful, in most cases close the dialog
      if (action.payload.ok === true) {
        state.activeDialog = null;
      }
    },
    denmDeleted(state, action: PayloadAction<{ result: ActionResult; id: string | string[] }>) {
      // If deleted DENM is currently selected, de-select it!
      if (
        state.activeDialog &&
        state.activeDialog.type === DialogType.DENM &&
        (Array.isArray(action.payload.id)
          ? action.payload.id.includes(state.activeDialog.id)
          : state.activeDialog.id === action.payload.id)
      ) {
        state.activeDialog = null;
      }
    },
    denmTerminated(state, action: PayloadAction<{ result: ActionResult; id: string }>) {
      // If terminated DENM is currently selected, de-select it!
      if (
        state.activeDialog &&
        state.activeDialog.type === DialogType.DENM &&
        state.activeDialog.id === action.payload.id
      ) {
        state.activeDialog = null;
      }
    },
    denmsDisabled(state, action: PayloadAction<{ result: ActionResult; ids: string[] }>) {
      // If disabled DENM is currently selected, de-select it!
      if (
        state.activeDialog &&
        state.activeDialog.type === DialogType.DENM &&
        action.payload.ids.includes(state.activeDialog.id)
      ) {
        state.activeDialog = null;
      }
    },
    ivimDeleted(state, action: PayloadAction<{ result: ActionResult; id: string | string[] }>) {
      // If deleted IVIM is currently selected, de-select it!
      if (
        state.activeDialog &&
        state.activeDialog.type === DialogType.IVIM &&
        (Array.isArray(action.payload.id)
          ? action.payload.id.includes(state.activeDialog.id)
          : state.activeDialog.id === action.payload.id)
      ) {
        state.activeDialog = null;
      }
    },
    ivimTerminated(state, action: PayloadAction<{ result: ActionResult; id: string }>) {
      // If terminated IVIM is currently selected, de-select it!
      if (
        state.activeDialog &&
        state.activeDialog.type === DialogType.IVIM &&
        state.activeDialog.id === action.payload.id
      ) {
        state.activeDialog = null;
      }
    },
    ivimsDisabled(state, action: PayloadAction<{ result: ActionResult; ids: string[] }>) {
      // If disabled IVIM is currently selected, de-select it!
      if (
        state.activeDialog &&
        state.activeDialog.type === DialogType.DENM &&
        action.payload.ids.includes(state.activeDialog.id)
      ) {
        state.activeDialog = null;
      }
    },
    rsuDeleted(state, action: PayloadAction<{ result: ActionResult; id: string }>) {
      // If deleted RSU is currently selected, de-select it!
      if (
        state.activeDialog &&
        state.activeDialog.type === DialogType.DEVICE &&
        state.activeDialog.id === action.payload.id
      ) {
        state.activeDialog = null;
      }
    },
    toastAdded(state, action: PayloadAction<ToastTitle>) {
      state.toasts.push({
        id: `${Date.now()}${Math.random()}`,
        title: action.payload,
      });
    },
    toastRemoved(state, action: PayloadAction<string>) {
      const index = state.toasts.findIndex((t) => t.id === action.payload);
      if (index >= 0) {
        state.toasts.splice(index, 1);
      }
    },
    mapPositionChanged(state, action: PayloadAction<number[]>) {
      const newPosition = action.payload;

      if (newPosition && state.activeLayers.rsu) {
        state.centerCoordsPosition = {
          center: {
            lat: newPosition[1],
            lng: newPosition[0],
          },
        };
        state.eventCoordsPosition = {
          center: {
            lat: newPosition[1],
            lng: newPosition[0],
          },
        };
        state.zoom = 20;
      } else {
        state.centerCoordsPosition = { ...initialState.centerCoordsPosition };
      }
    },

    updateZoom(state, action: PayloadAction<number>) {
      state.zoom = action.payload;
    },

    createPointStarted(state, action: PayloadAction<PointSelectionParams>) {
      state.currentSelection = action.payload.type;
      state.pointSelections = {
        active: true,
        pointCount: action.payload.pointCount,
        points: [],
        style: action.payload.style,
      };
    },

    nodeSelected(state, action: PayloadAction<MapNode>) {
      if (!state.manageSelections.active) {
        return;
      }
      switch (state.manageSelections.pointCount) {
        case PointCount.ONE:
          state.manageSelections.points = [action.payload];
          break;
        case PointCount.TWO:
          state.manageSelections.points = [
            ...state.manageSelections.points.slice(0, 1),
            action.payload,
          ];
          break;
        case PointCount.MANY:
          state.manageSelections.points.push(action.payload);
          break;
        default:
          break;
      }
    },
    setNumberTo(state, action: PayloadAction<number>) {
      state.manageSelections.pointsNumberTo.push(action.payload);
    },
    setNumberFrom(state, action: PayloadAction<number>) {
      state.manageSelections.pointsNumberFrom.push(action.payload);
    },
    manageSelectionAborted(state, _action: PayloadAction<void>) {
      state.currentSelection = null;
      state.manageSelections = {
        active: false,
        pointCount: PointCount.ONE,
        direction: null,
        points: [],
        pointsNumberTo: [],
        pointsNumberFrom: [],
        style: null,
      };
    },
    nodeSelectedDelete(state, action: PayloadAction<MapNode>) {
      if (!state.deletePoints.active) {
        return;
      }
      switch (state.deletePoints.pointCount) {
        case PointCount.ONE:
          state.deletePoints.points = [action.payload];
          break;
        case PointCount.TWO:
          state.deletePoints.points = [...state.deletePoints.points.slice(0, 1), action.payload];
          break;
        case PointCount.MANY:
          state.deletePoints.points.push(action.payload);
          break;
        default:
          break;
      }
    },

    deleteNumberTo(state, action: PayloadAction<number>) {
      state.deletePoints.pointsNumberTo.push(action.payload);
    },

    deleteNumberFrom(state, action: PayloadAction<number>) {
      state.deletePoints.pointsNumberFrom.push(action.payload);
    },

    deletePointsAborted(state, _action: PayloadAction<void>) {
      state.currentSelection = null;
      state.deletePoints = {
        active: true,
        pointCount: PointCount.MANY,
        direction: null,
        points: [],
        pointsNumberTo: [],
        pointsNumberFrom: [],
        style: null,
      };
    },
    startDeleteNode(state, _action: PayloadAction<void>) {
      state.deletePoints.active = true;
    },
    emptyLayerDelete(state, _action: PayloadAction<Coordinate[]>) {
      state.deletePoints = {
        active: true,
        pointCount: PointCount.MANY,
        direction: null,
        points: [],
        pointsNumberTo: [],
        pointsNumberFrom: [],
        style: null,
      };
    },
    nodesCreated(_state, _action: PayloadAction<{ result: ActionResult }>) {
      // Do nothing for now
    },
    nodesDeleted(_state, _action: PayloadAction<{ result: ActionResult }>) {
      // Do nothing for now
    },
    readyStatusReached(state, _action: PayloadAction<void>) {
      state.ready = true;
    },
    setUserLoggedIn(state, _action: PayloadAction<void>) {
      state.userLoggedIn = true;
    },
    setTheme(state, action: PayloadAction<{ theme: AppTheme }>) {
      state.theme = action.payload.theme;
    },
  },
});

export default applicationReducer;
