import { useLocalization } from '@fluent/react';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, InputAdornment, Popover, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { IvimStatusFilter } from '_store/ivim/ivimsTypes';
import React, { useState } from 'react';
import styles from '../styles';
import { PopoverFilters } from './PopoverFilters';

interface IvimListFiltersProps {
  searchFilter: string;
  setSearchFilter: (value: string) => void;
  setFilteredStatus?: (value: IvimStatusFilter) => void;
  setChecked?: (value: string[]) => void;
}

export const IvimListFilters = ({
  searchFilter,
  setSearchFilter,
  setFilteredStatus,
  setChecked,
}: IvimListFiltersProps) => {
  const { l10n } = useLocalization();
  const classes = styles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'ivim-list-popover' : undefined;
  return (
    <>
      <Box style={{ display: 'grid', gridTemplateColumns: '3fr 2fr', gap: 8 }} marginBottom={2}>
        <TextField
          style={{ marginTop: 16 }}
          key={'search'}
          label={l10n.getString('forms-ivim-search')}
          InputLabelProps={{ variant: 'standard' }}
          onChange={(ev) => setSearchFilter(ev.target.value)}
          fullWidth
          type="text"
          value={searchFilter}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton edge="start" size="small">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),

            endAdornment: searchFilter && (
              <IconButton onClick={() => setSearchFilter('')} size="small">
                <CloseIcon />
              </IconButton>
            ),
          }}
        />
        <Button
          variant="outlined"
          className={classes.filtersButton}
          endIcon={<FontAwesomeIcon icon={faFilter} size="xs" />}
          onClick={openFilters}
          size="small"
          color="primary"
        >
          {l10n.getString('forms-event-filters-button')}
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={classes.filtersPopover}>
            <PopoverFilters setChecked={setChecked} setFilteredStatus={setFilteredStatus} />
          </div>
        </Popover>
      </Box>
    </>
  );
};
