import { useLocalization } from '@fluent/react';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, FormControlLabel, Switch, Tooltip, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { TraceType } from './MainTrace';
import styles from './styles';

interface TraceProps {
  isActiveSelection: boolean;
  toggleSelectionTraces: (type: TraceType) => void;
  isTracesZoneDisabled: boolean;
}

export const Trace = ({
  isActiveSelection,
  toggleSelectionTraces,
  isTracesZoneDisabled,
}: TraceProps) => {
  const { l10n } = useLocalization();
  const classes = styles();

  const [automaticTrace, setAutomaticTrace] = useState<boolean>(true);

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      marginTop={'20px'}
      paddingBottom={'20px'}
      alignItems={'center'}
    >
      <Typography variant="body2">{l10n.getString('forms-denm-trace-zone')}</Typography>
      <FormControlLabel
        control={
          <Switch
            checked={automaticTrace}
            onChange={() => setAutomaticTrace(!automaticTrace)}
            name="automaticTrace"
            color="primary"
            size="small"
            disabled={isActiveSelection}
          />
        }
        label={
          automaticTrace
            ? l10n.getString('forms-denm-set-automatic-main-trace')
            : l10n.getString('forms-denm-set-manual-main-trace')
        }
        style={{ fontSize: 12 }}
      />

      <Tooltip
        title={
          isActiveSelection
            ? l10n.getString('forms-points-end-selection')
            : l10n.getString('forms-points-add-trace')
        }
      >
        <FontAwesomeIcon
          icon={isActiveSelection ? faCheck : faPlus}
          className={classes.traceZoneIcon}
          size="xs"
          onClick={() => {
            toggleSelectionTraces(automaticTrace ? TraceType.AUTOMATIC : TraceType.MANUAL);
          }}
        />
      </Tooltip>
    </Box>
  );
};
