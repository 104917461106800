import '../../components.scss';

import { useLocalization } from '@fluent/react';
import {
  Avatar,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@material-ui/core';
import { RootState, useAppDispatch } from '_store';
import applicationReducer from '_store/application/reducer';
import { AppTheme, DialogType } from '_store/application/types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { themeSelector } from '_store/application/selectors';
import { useAuth } from 'react-oidc-context';
import { IconMenu } from '../../icons';
import InfoView from '../InfoView';
import MapView from '../MapView';
import styles from './styles';

const connexUrl = process.env.CONNEX_URL;

const menuIconsTitles = [
  { type: DialogType.HOME, label: 'menu-home', active: true },
  { type: DialogType.DENM_LIST, label: 'menu-denm', active: false },
  { type: DialogType.IVIM_LIST, label: 'menu-ivim', active: false },
  { type: DialogType.CAM_LIST, label: 'menu-cam', active: false },
  { type: DialogType.DEVICE, label: 'menu-rsu', active: false },
  { type: DialogType.CONNEX, label: 'menu-connex', active: false },
  { type: DialogType.SETTINGS, label: 'menu-settings', active: false },
  // { type: DialogType.MAP, label: 'menu-map', active: false },
];

const SideMenu = () => {
  const { l10n } = useLocalization();
  const classes = styles();
  const auth = useAuth();
  const profile = auth?.user?.profile;

  const logout = useCallback(() => {
    auth.signoutRedirect({
      post_logout_redirect_uri: process.env.KEYCLOAK_REDIRECT_URI,
    });
  }, [auth]);

  const [menuIcons, setMenuIcons] = useState(menuIconsTitles);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const theme = useSelector(themeSelector);

  const [mapViewKey, setMapViewKey] = useState(0);

  useEffect(() => {
    // Incrementa la chiave per forzare il ri-rendering del MapView quando il tema cambia
    setMapViewKey((prevKey) => prevKey + 1);
  }, [theme]);

  // const devices = useSelector((state: RootState) => state.api.devices);
  // const denm = useSelector((state: RootState) => state.api.denm);
  // const nodes = useSelector((state: RootState) => state.api.nodes);

  const dispatch = useAppDispatch();

  const dialog = useSelector((state: RootState) => state.application.activeDialog);

  const setInfoView = (text: DialogType, index: number) => {
    if (index === 0 || text === dialog?.type) {
      dispatch(applicationReducer.actions.dialogClosed());
    } else {
      dispatch(applicationReducer.actions.infoSelected({ type: text, id: String(index) }));
    }
  };

  useEffect(() => {
    if (dialog) {
      const isDENMRelated = [DialogType.DENM, DialogType.DENM_PATH].includes(dialog.type);

      const isIVIMRelated = [DialogType.IVIM, DialogType.IVIM_PATH].includes(dialog.type);

      const isDEVICERelated = [DialogType.SINGLE_DEVICE, DialogType.DEVICE_PATH].includes(
        dialog.type,
      );

      const updateActive = menuIconsTitles.map((item) => ({
        ...item,
        active:
          item.type === dialog.type ||
          (isDENMRelated && item.type === DialogType.DENM_LIST) ||
          (isIVIMRelated && item.type === DialogType.IVIM_LIST) ||
          (isDEVICERelated && item.type === DialogType.DEVICE),
      }));

      setMenuIcons(updateActive);
    } else {
      setMenuIcons(menuIconsTitles);
    }
  }, [dialog]);

  const handleUserMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'user-popover' : undefined;

  return (
    <Container maxWidth={false} className={classes.container}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className="Bitmap" />

        <List className={classes.listStyle}>
          {menuIcons.map((icon, index) => {
            if (!connexUrl && icon.type === DialogType.CONNEX) return null;
            const isLabelLong = l10n.getString(icon.label).length >= 10;
            return (
              <ListItem
                button
                key={icon.type}
                className={icon.active ? 'box-selected' : 'box-unselected'}
                onClick={() =>
                  icon.type === DialogType.CONNEX
                    ? window.open(connexUrl, '_blank', 'noopener,noreferrer')
                    : setInfoView(icon.type, index)
                }
              >
                <ListItemIcon
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  {<IconMenu type={icon.type} active={icon.active} />}
                </ListItemIcon>
                <ListItemText
                  primary={l10n.getString(icon.label)}
                  className={icon.active ? 'label-selected' : 'unselected'}
                  classes={{ primary: isLabelLong && classes.menuItemTextSmall }}
                  style={{ color: theme === AppTheme.LIGHT ? 'red' : '#ffffff' }}
                />
              </ListItem>
            );
          })}
        </List>
        <Avatar aria-label="recipe" className={classes.avatar} onClick={handleUserMenu} />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={classes.userPopover}>
            <span>
              {`${profile?.firstName ?? ''} ${profile?.lastName ?? ''}`.trim() ||
                profile?.preferred_username ||
                ''}
            </span>
            <Button
              className={classes.subHeaderStyle}
              onClick={() => logout()}
              style={{ color: theme === AppTheme.LIGHT ? '#005abb' : '#ececec' }}
            >
              {l10n.getString('user-logout')}
            </Button>
          </div>
        </Popover>
      </Drawer>

      {dialog ? <InfoView /> : null}

      <MapView key={mapViewKey} />
    </Container>
  );
};

export default React.memo(SideMenu);
