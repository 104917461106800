import React from 'react';

import { keycloak } from '_lib/api/keycloak';
import { AuthProvider } from 'react-oidc-context';
import App from './app/app';
import { CssBaseline } from '@material-ui/core';

const AppWrapper = () => (
  <AuthProvider {...keycloak}>
    <CssBaseline />
    <App />
  </AuthProvider>
);

export default AppWrapper;
