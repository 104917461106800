import { useLocalization } from '@fluent/react';
import { faFileArrowDown, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DenmEtsiMetaData, DenmSincroMetaData, DenmSourceFilter } from '_store/denm/denmsTypes';
import {
  causesSelector,
  denmsAllData,
  loadingCSVSelector,
  subCausesSelector,
} from '_store/denm/selectors';
import { useDenmsRequest } from '_store/denm/utils';
import { formatDateForCSV } from '_store/utils';
import isEqual from 'lodash/isEqual';
import Papa from 'papaparse';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

interface ExportCSVProps {
  show: boolean;
  filename: string;
}

export const ExportCSV = ({ show = false, filename }: ExportCSVProps) => {
  const { l10n } = useLocalization();
  const prevTransformedDenmsRef = useRef<
    {
      [x: string]: string;
    }[]
  >();

  const [buttonClicked, setButtonClicked] = useState(false);

  const dispatchDenmsRequest = useDenmsRequest({ getAllData: true });
  const denmsToExport = useSelector(denmsAllData);
  const denmsCSVLoading = useSelector(loadingCSVSelector);

  const downloadLabel = l10n.getString('forms-event-download-csv');

  const filenameWithDate = useMemo(() => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}_${date
      .getHours()
      .toString()
      .padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;
    return `${filename}_${formattedDate}.csv`;
  }, [filename]);

  const causes = useSelector(causesSelector);
  const subCauses = useSelector(subCausesSelector);
  const subCausesMap = Object.fromEntries(subCauses.map((sc) => [sc.id, sc]));
  const causesMap = Object.fromEntries(causes.map((c) => [c.id, c]));

  const transformedDenms = useMemo(() => {
    return denmsToExport.map((denm, index) => {
      const subCause = subCausesMap[denm.subCauseId];
      const cause = subCause && causesMap[subCause.causeId];
      const sincro = denm.metadata?.sincro as DenmSincroMetaData;
      const endPointKm = sincro?.endPoint.km?.toString() ?? '';
      const endPointRoadTag = sincro?.endPoint.roadTag ?? '';
      const endPointDistance =
        sincro?.endPoint.distance !== undefined
          ? (sincro.endPoint.distance * 1000).toFixed(1).toString()
          : '';
      const startPointKm = sincro?.startPoint.km?.toString() ?? '';
      const startPointRoadTag = sincro?.startPoint.roadTag ?? '';
      const startPointDistance =
        sincro?.startPoint.distance !== undefined
          ? (sincro.startPoint.distance * 1000).toFixed(1).toString()
          : '';
      const dispatchable = sincro?.dispatchable;
      const nonDispatchableReason = sincro?.nonDispatchableReason;

      const etsi = denm.metadata?.etsi as DenmEtsiMetaData;
      const sequenceNumber = etsi?.actionID?.sequenceNumber?.toString();
      const originatingStationID = etsi?.actionID?.originatingStationID?.toString();
      const speedValue = etsi?.speedValue?.toString();
      const stationType = etsi?.stationType?.toString();
      const relevanceDistance = etsi?.relevanceDistance;
      const informationQuality = etsi?.informationQuality?.toString();
      const relevanceTrafficDirection = etsi?.relevanceTrafficDirection?.toString();
      const datexiiId = denm.datexiiId;

      return {
        ID: denm.id,
        [l10n.getString('forms-denm-init-date')]: formatDateForCSV(denm.createdAt),
        [l10n.getString('forms-denm-final-date')]: formatDateForCSV(denm.expiresAt),
        [l10n.getString('forms-denm-source')]:
          denm.source === DenmSourceFilter.CONNEX
            ? l10n.getString('forms-event-source-vehicles')
            : denm.source,
        [l10n.getString('forms-denm-cause')]: cause?.description,
        [l10n.getString('forms-denm-subcause')]: subCause?.description,
        [l10n.getString(
          'forms-denm-reference-point',
        )]: `${denm.referencePoint.coordinates[1]}, ${denm.referencePoint.coordinates[0]}`,
        [l10n.getString('forms-denm-datexii-id')]: datexiiId,
        'Sequence Number': sequenceNumber,
        'Originating Station ID': originatingStationID,
        'Speed Value': speedValue,
        'Station Type': stationType,
        'Relevance Distance': relevanceDistance,
        'Information Quality': informationQuality,
        'Relevance Traffic Direction': relevanceTrafficDirection,
        'GIS Start Point KM': startPointKm,
        'GIS Start Point Road Tag': startPointRoadTag,
        'GIS Start Point Distance (m)': startPointDistance,
        'GIS End Point KM': endPointKm,
        'GIS End Point Road Tag': endPointRoadTag,
        'GIS End Point Distance (m)': endPointDistance,
        'GIS Dispatchable': dispatchable ? 'Yes' : 'No',
        'GIS Non Dispatchable Reason': nonDispatchableReason,
      };
    });
  }, [denmsToExport, subCausesMap, causesMap]);

  const downloadCSV = () => {
    const csv = Papa.unparse(transformedDenms, { delimiter: ';' });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filenameWithDate;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (buttonClicked && !isEqual(prevTransformedDenmsRef.current, transformedDenms)) {
      downloadCSV();
      setButtonClicked(false);
    }
    prevTransformedDenmsRef.current = transformedDenms;
  }, [transformedDenms]);

  const handleClick = () => {
    dispatchDenmsRequest();
    setButtonClicked(true);
  };

  if (!show) {
    return null;
  }

  return (
    <button onClick={handleClick}>
      {downloadLabel}
      {denmsCSVLoading ? (
        <FontAwesomeIcon icon={faSpinner} spin style={{ marginLeft: 10 }} />
      ) : (
        <FontAwesomeIcon icon={faFileArrowDown} size="lg" style={{ marginLeft: 10 }} />
      )}
    </button>
  );
};
