import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import React from 'react';
import styles from './styles';

interface CheckboxItemProps {
  checked: boolean;
  itemId: string;
  handleCheckedChange: (id: string) => void;
  size?: 'small' | 'medium';
  disableRipple?: boolean;
  label?: string;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  tooltip?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
}

export const CheckboxItem = ({
  checked,
  itemId,
  handleCheckedChange,
  size = 'small',
  disableRipple = true,
  label,
  labelPlacement = 'end',
  tooltip,
  tooltipPlacement = 'right',
}: CheckboxItemProps) => {
  const classes = styles();

  const checkbox = (
    <Checkbox
      checked={checked}
      onChange={() => handleCheckedChange(itemId)}
      name={itemId}
      color="default"
      size={size}
      disableRipple={disableRipple}
    />
  );
  const control = label ? (
    <FormControlLabel
      control={checkbox}
      label={label}
      labelPlacement={labelPlacement}
      classes={{ root: classes.formControl, label: classes.formControlLabel }}
    />
  ) : (
    checkbox
  );

  return tooltip ? (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      {control}
    </Tooltip>
  ) : (
    control
  );
};
