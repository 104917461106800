import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  cardStyle: {
    display: 'flex',
    flexDirection: 'column',
    // marginTop: '0vh',
    marginBottom: '1.5vh',
    flex: 1,
  },
  cardContent: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  mapContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  mapButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > *': {
      margin: theme.spacing(1),
      // marginRight: 50
    },
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 400,
  },
  inlineHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  tooltipHeader: {
    fontSize: '1.3rem',
    fontWeight: 'normal',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
  },
  tooltipSubHeader: {
    fontSize: '0.9rem',
    fontWeight: 'normal',
    margin: '0.2rem 0 0.5rem 0',
  },
  tooltipTableHeader: {
    textAlign: 'left',
  },
  captionStyle: {
    justifyContent: 'space-between',
    display: 'flex',
    marginBottom: 5,
    color: '#82919b',
  },
  boxChart: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  titleChart: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#444e53',
  },
  themeSwitch: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    position: 'absolute',
    left: 20,
    bottom: 5,
    zIndex: 400,
    padding: '5px 10px 0 0',
    border: '1px solid #82919b',
  },
}));

export default styles;
