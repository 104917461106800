import { makeStyles } from '@material-ui/core';

const styles = makeStyles((_theme) => ({
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
  },
  bulkButton: {
    marginTop: 7,
  },
  filtersButton: {
    marginTop: 35,
  },
  filtersPopover: {
    padding: 20,
    width: 500,
  },
}));

export default styles;
