import { grey } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

const colors = {
  fillBlue: '#005ABB',
  fillGreyLighter: '#eff1f0',
  fillGreyDarker: '#444e53',
  greyLight: '#ececec',
  greyDark: '#212121',
  greyDarker: '#424242',
  fillLightBlue: '#eef6ff',
  fillWhite: '#ffffff',
  fillGreyDark: '#82919b',
};

const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors.fillBlue,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '15px',
        },
        button: {
          height: '30px',
          border: `1px solid ${colors.fillBlue}`,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 90, 187, 0.04)',
          },
          backgroundColor: 'transparent',
          color: colors.fillBlue,
          padding: '10px 0 25px 0',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontWeight: 'bold',
          borderRadius: 4,
          marginBottom: 30,
          textTransform: 'none',
        },
        '.Main': {
          backgroundColor: colors.fillGreyLighter,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          marginLeft: 75,
        },
        '.MuiPaginationItem-textPrimary.Mui-selected': {
          backgroundColor: colors.fillBlue,
        },
        '.Map-Road-select, .Map-Type-select': {
          height: '45px',
          margin: '0 5px 0 0 !important',
          borderRadius: '22px !important',
          boxShadow: 'none',
          border: `solid 2px ${colors.fillBlue} !important`,
          backgroundColor: `${colors.fillLightBlue} !important`,
          textTransform: 'none',
          padding: '15px',
        },
        '.MuiInput-underline:hover:not(.Mui-disabled)::before, .MuiInput-underline::before, .MuiInput-underline::after':
          {
            content: '',
            transition: 'none',
            borderBottom: 'none',
          },
        '.MuiSelect-select': {
          margin: '0 20px',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
          {
            border: 'none',
          },
        '.Map-buttons': {
          width: '160px',
          height: '45px',
          margin: '0 5px 0 0 !important',
          padding: '14px 61px 13px 62px',
          borderRadius: '22px !important',
          border: 'solid 2px #c6cdcf !important',
          backgroundColor: 'white !important',
          textTransform: 'none',
        },
        '.Map-buttons-sel': {
          width: '160px',
          height: '45px',
          margin: '0 5px 0 0 !important',
          borderRadius: '22px !important',
          border: `solid 2px ${colors.fillBlue} !important`,
          backgroundColor: `${colors.fillLightBlue} !important`,
          textTransform: 'none',
        },
        '.label-selected > span': {
          color: `${colors.fillBlue} !important`,
        },
        '.unselected > span': {
          color: `${colors.fillGreyDark} !important`,
        },
      },
    },
    MuiButton: {
      outlinedPrimary: {
        border: `1px solid ${colors.fillBlue}`,
        textTransform: 'none',
        height: 30,
      },
      outlined: {
        textTransform: 'none',
        height: 30,
        border: `1px solid ${grey[800]}`,
      },
      textPrimary: {
        textTransform: 'none',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    MuiCheckbox: {
      root: {
        '&$checked': {
          color: colors.fillBlue,
        },
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTypography: {
      root: {
        color: colors.fillGreyDarker,
      },
    },
    MuiContainer: {
      root: {
        backgroundColor: colors.fillGreyLighter,
      },
    },
    MuiMenuItem: {
      root: {
        underline: 'none',
      },
    },
    MuiSelect: {
      outlined: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiDialogActions: {
      root: {
        '& .MuiButton-root': {
          textTransform: 'uppercase',
        },
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: {
      main: colors.greyLight,
    },
    type: 'dark',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: '15px',
        },
        button: {
          height: '30px',
          border: `1px solid ${colors.greyLight}`,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: `rgba(236,236,236, 0.04)`,
          },
          backgroundColor: 'transparent',
          color: colors.greyLight,
          padding: '10px 0 25px 0',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontWeight: 'bold',
          borderRadius: 4,
          marginBottom: 30,
          textTransform: 'none',
        },
        '.Main': {
          backgroundColor: colors.greyDark,
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          marginLeft: 75,
        },
        '.MuiPaginationItem-textPrimary.Mui-selected': {
          color: `${colors.greyLight} !important`,
        },
        '.Map-Road-select, .Map-Type-select': {
          height: '45px',
          margin: '0 5px 0 0 !important',
          borderRadius: '22px !important',
          boxShadow: 'none',
          border: `solid 2px ${colors.greyDark} !important`,
          backgroundColor: `${colors.greyDarker} !important`,
          textTransform: 'none',
          padding: '15px',
        },
        '.MuiInput-underline:hover:not(.Mui-disabled)::before, .MuiInput-underline::before, .MuiInput-underline::after':
          {
            content: '',
            transition: 'none',
            borderBottom: 'none',
          },
        '.MuiSelect-select': {
          margin: '0 20px',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
          {
            border: 'none',
          },
        '.Map-buttons': {
          width: '160px',
          height: '45px',
          margin: '0 5px 0 0 !important',
          padding: '14px 61px 13px 62px',
          borderRadius: '22px !important',
          border: `solid 2px ${colors.greyLight} !important`,
          backgroundColor: `${colors.greyLight} !important`,
          textTransform: 'none',
          color: `${colors.greyDarker} !important`,
        },
        '.Map-buttons-sel': {
          width: '160px',
          height: '45px',
          margin: '0 5px 0 0 !important',
          borderRadius: '22px !important',
          border: `solid 2px ${colors.greyDark} !important`,
          backgroundColor: `${colors.greyDarker} !important`,
          textTransform: 'none',
          color: `${colors.greyLight} !important`,
        },
        '.MuiPickersDay-daySelected, .MuiPickersClockNumber-clockNumberSelected': {
          color: `${colors.fillWhite} !important`,
        },
        '.label-selected > span': {
          color: `${colors.fillBlue} !important`,
        },
        '.unselected > span': {
          color: `${colors.greyLight} !important`,
        },
      },
    },
    MuiButton: {
      outlinedPrimary: {
        color: colors.greyLight,
        border: `1px solid ${colors.fillBlue}`,
        backgroundColor: colors.fillBlue,
        textTransform: 'none',
        height: 30,
      },
      outlined: {
        textTransform: 'none',
        height: 30,
        border: `1px solid ${colors.greyLight}`,
      },
      textPrimary: {
        textTransform: 'none',
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        '&$checked': {
          color: colors.greyLight,
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTypography: {
      root: {
        color: colors.greyLight,
      },
    },
    MuiListItemText: {
      primary: {
        color: colors.greyLight,
      },
    },
    MuiContainer: {
      root: {
        backgroundColor: colors.greyDark,
      },
    },
    MuiSelect: {
      outlined: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiDialogActions: {
      root: {
        '& .MuiButton-root': {
          textTransform: 'uppercase',
        },
      },
    },
  },
});

export { lightTheme, darkTheme };
