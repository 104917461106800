import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  formControl: {
    marginLeft: 0,
  },
  formControlLabel: {
    fontSize: '14px',
    marginLeft: 10,
  },
}));

export default styles;
