import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';
import { ApplicationState } from './types';

const applicationStateDomain = (state: RootState) => state.application;

export const mapPositionSelector = createSelector(
  applicationStateDomain,
  (application: ApplicationState) => application.centerCoordsPosition,
);

export const zoomSelector = createSelector(
  applicationStateDomain,
  (application: ApplicationState) => application.zoom,
);

export const dialogSelector = createSelector(
  applicationStateDomain,
  (application: ApplicationState) => application.activeDialog,
);

export const extraSelector = createSelector(
  applicationStateDomain,
  (application: ApplicationState) => application.extraMapElements,
);

export const extraTraceMapElementsSelector = createSelector(
  applicationStateDomain,
  (application: ApplicationState) => application.extraTraceMapElements,
);

export const selectionSelector = createSelector(
  applicationStateDomain,
  (application: ApplicationState) => application.pointSelections,
);

export const activeLayersSelector = createSelector(
  applicationStateDomain,
  (application: ApplicationState) => application.activeLayers,
);

export const countLayersSelector = createSelector(
  applicationStateDomain,
  (application: ApplicationState) => application.countLayers,
);

export const themeSelector = createSelector(
  applicationStateDomain,
  (application: ApplicationState) => application.theme,
);
