import React, { FC } from 'react';
import { CardContent, Divider } from '@material-ui/core';
import { MeasureDistance } from '../Tools/MeasureDistance';
import { themeSelector } from '_store/application/selectors';
import { useSelector } from 'react-redux';
import { AppTheme } from '_store/application/types';

interface ContextMenuProps {
  point: { x: number; y: number };
  position: { lat: number; lng: number };
  onClose: () => void;
}

const ContextMenu: FC<ContextMenuProps> = ({ point, position, onClose }) => {
  const theme = useSelector(themeSelector);

  return (
    <CardContent
      className="context-menu"
      style={{
        position: 'absolute',
        top: `${point.y}px`,
        left: `${point.x}px`,
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme === AppTheme.LIGHT ? '#ffffff' : '#424242',
        borderRadius: 4,
        padding: 16,
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
      }}
    >
      <span
        style={{
          color: theme === AppTheme.LIGHT ? '#000000' : '#ececec',
          fontSize: '0.875rem',
          fontWeight: 400,
          marginBottom: 8,
        }}
      >{`${position.lat}, ${position.lng}`}</span>
      <MeasureDistance onClose={onClose} />
    </CardContent>
  );
};

export default ContextMenu;
