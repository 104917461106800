import { makeStyles } from '@material-ui/core';

const drawerWidth = 75;

const styles = makeStyles((_theme) => ({
  drawer: {
    flexShrink: 1,
  },
  drawerPaper: {
    width: drawerWidth,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 10,
    overflowX: 'hidden',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
    paddingRight: 15,
    paddingLeft: 15,
  },

  imageIcon: {
    height: '100%',
  },

  listStyle: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  avatar: {
    backgroundColor: '#444e53',
    marginBottom: 20,
    cursor: 'pointer',
  },
  subHeaderStyle: {
    textDecorationLine: 'underline',
    textTransform: 'none',
    padding: 0,
  },
  userPopover: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
    '& span': {
      fontWeight: 'bold',
    },
  },
  menuItemTextSmall: {
    fontSize: '0.7rem',
  },
}));

export default styles;
