export interface CAMEventInfo {
  stationtype: number;
  icon: string;
  text: string;
}

export interface DENMCauseInfo {
  cause: number;
  text: string;
}

export interface DENMEventInfo {
  cause: number;
  subcause: number;
  textSubcause: string;
  text: string;
  icon: string;
}

export interface IVIMPictrogram {
  pictogramCategoryCode: number;
  pictogramSerialNumber: number;
  serviceCategoryCode: string;
  serviceSubCategoryCode: string;
  text: string;
  icon: string;
  description: string;
}

export interface GUIEventResponse {
  dictionaries: {
    camEvents: CAMEventInfo[];
    denmCauses: DENMCauseInfo[];
    denmEvents: DENMEventInfo[];
    ivimEvents: IVIMPictrogram[];
  };
}

export interface GUIData {
  dictionaries: {
    camEvents: Record<number, CAMEventInfo>;
    denmCauses: Record<number, string>;
    denmEvents: DENMEventInfo[];
    ivimEvents: IVIMPictrogram[];
  };
}

export interface GUIDefaultsResponse {
  forms: FormDefaults[];
  rsuTypes: RSUOption[];
}

export type FormDefaults = DENMFormDefaults | IVIMFormDefaults;

export interface DENMFormDefaults {
  formType: 'denm';
  validityPeriod: number[];
  frequencyBroadcasting: number[];
}

export interface IVIMFormDefaults {
  formType: 'ivim';
  serviceProviderCountryCode: number[];
  providerIdentifier: number[];
  messageIdentificationNumber: number[];
  status: string[];
  type: string[];
  languageText: (string | number)[];
  frequencyBroadcasting: number[];
}

export interface RSUOption {
  rsuBrand: string;
  communicationChannel: string;
}

interface Polyline {
  lat: number;
  lng: number;
}

export enum TrafficFlowCode {
  UNKNOWN = 'unknown',
  NOMINAL = 'nominal',
  SLOWDOWNS = 'slowdowns',
  CONGESTED = 'congested',
}

export interface TrafficDataInfo {
  link: number;
  direction: '1' | '-1';
  flowCode: TrafficFlowCode;
  polyline: Polyline[];
}

export interface Diagnostic {
  // id: string;
  statusDetails: DiagnosticStatusDetails;
  service: boolean;
  status: string;
}

export type DiagnosticStatusDetails = {
  navFixIsValid: boolean;
  deviceUid: string;
  stationId: string;
  cpuTemp: number;
  diskStatus: boolean;
  diskFree: number;
  stackUptime: string;
  interface1: {
    txPacket: number;
    rxPacket: number;
    rxRssiLastPacket: number;
  };
  ipAddress: string;
};

export interface RsuDiagnostic {
  data: {
    status: {
      name: string;
      // eslint-disable-next-line camelcase
      rsu_id: string;
      status: DiagnosticStatusDetails;
    };
  };
  event: string;
}

export interface APIState {
  pollActive: boolean;
  runningUpdates: Record<string, boolean>;
  lastUpdate: Date;
  diagnostic: {
    [id: string]: Diagnostic;
  };
}
