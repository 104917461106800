import { useLocalization } from '@fluent/react';
import { Box, FormControlLabel, Switch, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ButtonGroup from '../../../../../components/items/ButtonGroup';
import { DenmColors } from '../../../../../components/routes/MapView/utils';

export enum TraceType {
  AUTOMATIC = 'Automatic',
  MANUAL = 'Manual',
}

interface MainTraceProps {
  selectedTraceIndex: number;
  highlightTrace: (index: number) => void;
  isDisabled: boolean;
  isActiveSelection: boolean;
  mainTraceLength: number;
  toggleSelectionMainTrace: (type: TraceType) => void;
  deleteMainTrace: () => void;
}

export const MainTrace = ({
  selectedTraceIndex,
  highlightTrace,
  isDisabled,
  isActiveSelection,
  mainTraceLength,
  toggleSelectionMainTrace,
  deleteMainTrace,
}: MainTraceProps) => {
  const { l10n } = useLocalization();
  const [automaticMainTrace, setAutomaticMainTrace] = useState<boolean>(true);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        marginTop={'30px'}
        borderBottom={'1px dashed rgb(0, 90, 187)'}
        paddingBottom={'20px'}
      >
        <Typography
          style={{
            alignSelf: 'center',
            cursor: 'pointer',
            borderLeft: selectedTraceIndex === -1 ? `4px solid ${DenmColors.TRACE_SELECTED}` : '',
            paddingLeft: selectedTraceIndex === -1 ? '10px' : '0px',
          }}
          variant="caption"
          onClick={() => (!isDisabled ? highlightTrace(-1) : null)}
        >
          {`${l10n.getString('forms-denm-single-trace-zone-main')}`}
          <span style={{ color: 'red', marginLeft: 5 }}>*</span>
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={automaticMainTrace}
              onChange={() => setAutomaticMainTrace(!automaticMainTrace)}
              name="automaticMainTrace"
              color="primary"
              size="small"
              disabled={isActiveSelection}
            />
          }
          label={
            automaticMainTrace
              ? l10n.getString('forms-denm-set-automatic-main-trace')
              : l10n.getString('forms-denm-set-manual-main-trace')
          }
          style={{ fontSize: 12 }}
        />
      </Box>
      <Box>
        <ButtonGroup
          text=""
          buttons={[
            {
              label:
                mainTraceLength <= 0
                  ? isActiveSelection
                    ? l10n.getString('forms-points-end-selection')
                    : l10n.getString('forms-points-start-selection')
                  : l10n.getString('forms-denm-points-delete'),
              disabled: isDisabled,
              color: 'primary',
            },
          ]}
          onClick={() => {
            mainTraceLength <= 0
              ? toggleSelectionMainTrace(
                  automaticMainTrace ? TraceType.AUTOMATIC : TraceType.MANUAL,
                )
              : deleteMainTrace();
          }}
        />
      </Box>
    </Box>
  );
};
