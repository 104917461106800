import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { ThemeWrapper } from './theme/ThemeWrapper';
import { AppLocalizationProvider } from './app/locale/l10n';
import AppWrapper from './AppWrapper';
import store from './store';

const Container = () => (
  <Provider store={store}>
    <AppLocalizationProvider>
      <ThemeWrapper>
        <AppWrapper />
      </ThemeWrapper>
    </AppLocalizationProvider>
  </Provider>
);

render(<Container />, document.getElementById('root'));
