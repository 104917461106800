import * as turf from '@turf/turf';
import { Coordinate, DiagnosticData } from '_lib/api';
import { TrafficFlowCode } from '_store/api/types';
import { MapElement, MapElementType, PointSelectionType } from '_store/application/types';
import { RsuStatusCode } from '_store/devices/devicesTypes';
import { Road, Waypoints } from '_store/roads/roadsTypes';
import { LatLng, LatLngBounds, LatLngLiteral, Map as LeafletMap } from 'leaflet';

import IconGreenRsu from '../../icons/cam/greenRsu.png';
import IconData from '../../icons/cam/icon-data.png';
import IconNoDiagnostic from '../../icons/cam/noDiagnostic.png';
import IconRedRsu from '../../icons/cam/redRsu.png';
import IconYellowRsu from '../../icons/cam/yellowRsu.png';

export const iconSizePx = 32;

export const allUp = (diag: DiagnosticData): boolean =>
  diag.statusharddisk > 0 && diag.statusserviceUDP > 0;

export const rsuStatusIcon = (status: RsuStatusCode) => {
  switch (status) {
    case RsuStatusCode.UP:
      return IconGreenRsu;
    case RsuStatusCode.WARNING:
      return IconYellowRsu;
    case RsuStatusCode.DOWN:
    case RsuStatusCode.ERROR:
      return IconRedRsu;
    case RsuStatusCode.AGG:
      return IconData;
    case RsuStatusCode.UNKNOWN:
    default:
      return IconNoDiagnostic;
  }
};

export function toLatLng(dbloc: Coordinate): LatLngLiteral {
  return { lng: dbloc[0], lat: dbloc[1] };
}

export const colorByFlowCode = (flowCode: TrafficFlowCode): string => {
  switch (flowCode) {
    case TrafficFlowCode.NOMINAL:
      return '#3a0';
    case TrafficFlowCode.SLOWDOWNS:
      return '#ca0';
    case TrafficFlowCode.CONGESTED:
      return '#a30';
    default:
      return '#555';
  }
};

export const calculateCenterCoordinates = (
  roads: Road[],
  selectedRoadSegmentIds: string[],
  defaultCenter: LatLngLiteral | LatLng,
): LatLngLiteral | LatLng => {
  const groupOfCoordinates = selectedRoadSegmentIds.flatMap(
    (id) => roads.find((r) => r.id === id)?.waypoints.coordinates || [],
  );
  if (groupOfCoordinates.length > 0) {
    const coordinates = groupOfCoordinates.flat();
    const centerCoords = coordinates.reduce(
      (previousCoords, currentCoords) => [
        previousCoords[0] + currentCoords[0] / coordinates.length,
        previousCoords[1] + currentCoords[1] / coordinates.length,
      ],
      [0, 0],
    );
    return { lat: centerCoords[1], lng: centerCoords[0] };
  }
  return defaultCenter;
};

export const findCoordinatesBounds = (coordinates: Coordinate[]): LatLngBounds => {
  const bounds = coordinates.reduce(
    (previousBounds, coord) => {
      const [bounds0, bounds1] = [...previousBounds];
      const [lng, lat] = coord;
      if (lng < previousBounds[0].min) {
        bounds0.min = lng;
      } else if (lng > previousBounds[0].max) {
        bounds0.max = lng;
      }
      if (lat < previousBounds[1].min) {
        bounds1.min = lat;
      } else if (lat > previousBounds[1].max) {
        bounds1.max = lat;
      }
      return [bounds0, bounds1];
    },
    [
      { min: Number.POSITIVE_INFINITY, max: Number.NEGATIVE_INFINITY },
      { min: Number.POSITIVE_INFINITY, max: Number.NEGATIVE_INFINITY },
    ],
  );

  return new LatLngBounds(
    // southWest
    { lat: bounds[1].min, lng: bounds[0].min },
    // northEast
    { lat: bounds[1].max, lng: bounds[0].max },
  );
};

export const getBoundsZoomLevel = (bounds: LatLngBounds, mapRef: LeafletMap): number => {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  const latRad = (lat) => {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  };

  const zoom = (mapPx, worldPx, fraction) =>
    Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);

  const southWest = bounds.getSouthWest();
  const northEast = bounds.getNorthEast();

  const latFraction = (latRad(northEast.lat) - latRad(southWest.lat)) / Math.PI;

  const lngDiff = northEast.lng - southWest.lng;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const mapContainerSize = mapRef.getSize();
  const latZoom = zoom(mapContainerSize.y, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapContainerSize.x, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

export const metersPerPixel = (lat: number, zoomLevel: number): number =>
  (156543.03392 * Math.cos((lat * Math.PI) / 180)) / 2 ** zoomLevel;

export enum DenmColors {
  TRACE = '#f0c',
  MAIN_TRACE = '#003aff',
  REFERENCE_POINT = '#f00',
  HISTORY = '#fc0',
  TRACE_SELECTED = '#581845',
}

export const pointStyles: Record<PointSelectionType, MapElement> = {
  [PointSelectionType.DENM_REFERENCE_POINT]: {
    type: MapElementType.CIRCLE,
    radius: 10,
    options: {
      weight: 3,
      color: DenmColors.REFERENCE_POINT,
    },
    loc: [0, 0],
  },
  [PointSelectionType.DENM_HISTORY]: {
    type: MapElementType.CIRCLE,
    radius: 6,
    options: {
      weight: 3,
      color: DenmColors.HISTORY,
    },
    loc: [0, 0],
  },
  [PointSelectionType.DENM_TRACES]: {
    type: MapElementType.CIRCLE,
    radius: 6,
    options: {
      weight: 3,
      color: DenmColors.TRACE,
    },
    loc: [0, 0],
  },
  [PointSelectionType.IVIM_REFERENCE_POINT]: {
    type: MapElementType.CIRCLE,
    radius: 10,
    options: {
      weight: 3,
      color: '#f0f',
    },
    loc: [0, 0],
  },
  [PointSelectionType.IVIM_DET_ZONE]: {
    type: MapElementType.CIRCLE,
    radius: 6,
    options: {
      weight: 3,
      color: '#0fc',
    },
    loc: [0, 0],
  },
  [PointSelectionType.IVIM_REL_ZONE]: {
    type: MapElementType.CIRCLE,
    radius: 6,
    options: {
      weight: 3,
      color: '#00f',
    },
    loc: [0, 0],
  },
  [PointSelectionType.MEASUREMENT]: {
    type: MapElementType.CIRCLE,
    radius: 3,
    options: {
      weight: 2,
      color: '#bb6100',
    },
    loc: [0, 0],
  },
};

export const equidistantSegments = (
  multiLineString: Waypoints,
  startPoint: Coordinate,
  endPoint: Coordinate,
  checkSameLine: boolean = true,
) => {
  const { lineIndex: startLineIndex, point: startPointSnapped } = findNearestPoint(
    startPoint,
    multiLineString,
  );
  const { lineIndex: endLineIndex, point: endPointSnapped } = findNearestPoint(
    endPoint,
    multiLineString,
  );

  if (checkSameLine && startLineIndex !== endLineIndex) {
    throw new Error();
  }

  const lineToUse =
    checkSameLine || startLineIndex === endLineIndex
      ? turf.lineString(multiLineString.coordinates[startLineIndex])
      : turf.lineString(
          multiLineString.coordinates[(startLineIndex + 1) % multiLineString.coordinates.length],
        );

  const newStartPoint = checkSameLine
    ? startPointSnapped
    : turf.nearestPointOnLine(lineToUse, startPoint);
  const slice = sliceOfEquidistantSegments(lineToUse, newStartPoint, endPointSnapped);

  if (!checkSameLine) {
    slice.geometry.coordinates.unshift(startPoint);
  }

  return slice;
};

const findNearestPoint = (point: Coordinate, multiLineString: Waypoints) => {
  let nearestPoint;
  let nearestDistance = Infinity;

  multiLineString.coordinates.forEach((lineCoords, lineIndex) => {
    const snappedPoint = turf.nearestPointOnLine(turf.lineString(lineCoords), point);
    const currentDistance = snappedPoint.properties.dist;

    if (currentDistance < nearestDistance) {
      nearestDistance = currentDistance;
      nearestPoint = { point: snappedPoint, lineIndex };
    }
  });

  return nearestPoint;
};

const sliceOfEquidistantSegments = (lineString, startPoint, endPoint, numberOfPoints = 40) => {
  let isStartAfterEnd = false;
  let start = startPoint;
  let end = endPoint;

  if (startPoint.properties.location > endPoint.properties.location) {
    isStartAfterEnd = true;
    start = endPoint;
    end = startPoint;
  }

  const splitAtStart = turf.lineSplit(lineString, start);
  const sliceAfterStart = splitAtStart.features[1]; // Take the slice after the start point

  const splitAtEnd = turf.lineSplit(sliceAfterStart, end);
  const sliceBetween = splitAtEnd.features[0]; // Take the slice before the end point

  const sliceLength = turf.length(sliceBetween);
  const distanceBetweenPoints = sliceLength / (numberOfPoints - 1);

  // Collect the equidistant points along the slice
  const points: any = [];
  for (let i = 0; i < numberOfPoints; i++) {
    const pointAtDistance = turf.along(sliceBetween, distanceBetweenPoints * i);
    points.push(pointAtDistance.geometry.coordinates);
  }

  if (isStartAfterEnd) {
    points.reverse();
  }

  return turf.lineString(points);
};

export const measureDistance = (start: Coordinate, end: Coordinate): number => {
  return turf.distance(start, end, { units: 'kilometers' });
};
