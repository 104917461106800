import { CircularProgress } from '@material-ui/core';
import { RouteComponentProps, Router } from '@reach/router';
import { RootState, useAppDispatch } from '_store';
import apiSagas from '_store/api/sagas';
import React, { ReactElement, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import CustomToast from './components/items/CustomToast';
import SideMenu from './components/routes/SideMenu';
import TopMenu from './components/routes/TopMenu';

const Home: React.FC<RouteComponentProps> = (): ReactElement => {
  const toasts = useSelector((state: RootState) => state.application.toasts);

  return (
    <main className="Main">
      <TopMenu />
      <SideMenu />
      {toasts.map((t) => (
        <CustomToast key={t.id} id={t.id} title={t.title} />
      ))}
    </main>
  );
};

export default function App(): ReactElement {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  const dispatch = useAppDispatch();

  const { ready, isUserLoggedIn } = useSelector((state: RootState) => ({
    ready: state.application.ready,
    isUserLoggedIn: state.application.userLoggedIn,
  }));

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
      return;
    }

    if (auth.isAuthenticated) {
      const action = isUserLoggedIn
        ? apiSagas.actions.renewToken({ token: auth.user?.access_token })
        : apiSagas.actions.createClientFromCredentials({ token: auth.user?.access_token });

      dispatch(action);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading && !auth.activeNavigator) {
    return <div>Loading...</div>;
  }

  if (!auth.isAuthenticated) {
    return <div>Not authenticated, redirecting to log in</div>;
  }

  if (!ready) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <CircularProgress />
        <div style={{ marginTop: '1rem' }}>Loading required data</div>
      </div>
    );
  }

  return (
    <Router className="container">
      <Home path="/" />
    </Router>
  );
}
