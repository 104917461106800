import { ThemeProvider } from '@material-ui/core';
import { RootState, useAppDispatch } from '../store';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFromLocalStorage } from '../utils';
import { AppTheme } from '../store/application/types';
import applicationReducer from '../store/application/reducer';
import { lightTheme, darkTheme } from './theme';

export const ThemeWrapper: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const theme = useSelector((state: RootState) => state.application.theme ?? 'light');

  const appThemeFromLocalStorage = useMemo(
    () => (getFromLocalStorage('appTheme') as AppTheme) ?? AppTheme.LIGHT,
    [],
  );

  useEffect(() => {
    if (appThemeFromLocalStorage !== theme) {
      dispatch(applicationReducer.actions.setTheme({ theme: appThemeFromLocalStorage }));
    }
  }, []);

  const materialTheme = React.useMemo(
    () => (theme === AppTheme.DARK ? darkTheme : lightTheme),
    [theme],
  );

  return <ThemeProvider theme={materialTheme}>{children}</ThemeProvider>;
};
