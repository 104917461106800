import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { APIState, RsuDiagnostic } from './types';

const initialState: APIState = {
  pollActive: true,
  runningUpdates: {},
  lastUpdate: new Date(),
  diagnostic: {},
};

const apiReducer = createSlice({
  name: 'api',
  initialState,
  reducers: {
    updateStarted(state, action: PayloadAction<string>) {
      state.runningUpdates[action.payload] = true;
    },
    updateFinished(state, action: PayloadAction<string>) {
      state.runningUpdates[action.payload] = false;
      state.lastUpdate = new Date();
    },
    pollStatusChanged(state, { payload }: PayloadAction<boolean>) {
      state.pollActive = payload;
    },
    upsertDiagnostic(state, { payload }: PayloadAction<RsuDiagnostic>) {
      const { data, event } = payload;
      const {
        rsu_id: id,
        status: {
          diskStatus,
          diskFree,
          navFixIsValid,
          deviceUid,
          stationId,
          cpuTemp,
          stackUptime,
          interface1,
          ipAddress,
        },
      } = data.status;
      state.diagnostic[id] = {
        statusDetails: {
          diskStatus,
          diskFree,
          navFixIsValid,
          deviceUid,
          stationId,
          cpuTemp,
          stackUptime,
          interface1,
          ipAddress,
        },
        service: true,
        status: event,
      };
    },
    deleteDiagnostic(state, { payload }: PayloadAction<RsuDiagnostic>) {
      const { data } = payload;
      const { rsu_id: id } = data.status;
      delete state.diagnostic[id];
    },
  },
});

export default apiReducer;
