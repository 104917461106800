import { useDispatch, useSelector } from 'react-redux';

import denmFormReducer from '_store/forms/manage-denm';
import { selectedRoadSegmentIdsSelector } from '_store/roads/selectors';
import { isFuture } from 'date-fns';
import { useEffect, useMemo } from 'react';
import denmsReducer from './denmsReducers';
import { Causes, Denm, SubCauses } from './denmsTypes';
import {
  causesSelector,
  denmFilterCauseSelector,
  denmFilterDateSelector,
  denmFilterExcludeCauseAndSubcauseSelector,
  denmFilterSincroDisaptchableSelector,
  denmFilterSourceSelector,
  denmFilterStatusSelector,
  denmFilterSubCauseSelector,
  denmFilterUpdatedFromSelector,
  denmFilterUpdatedToSelector,
  denmsSelectorForSelectedRoadSegment,
  subCausesByCauseOptionalSelector,
  subCausesSelector,
} from './selectors';
import { RelevanceDistance } from '_lib/api';

export const defaultIcon = 'Accident_2.png';

export const useCause = (denm?: Denm): [Causes, SubCauses] => {
  if (!denm) {
    return [undefined, undefined];
  }
  const causes = useSelector(causesSelector);
  const subCauses = useSelector(subCausesSelector);
  const subCause = subCauses.find((sc) => sc.id === denm?.subCauseId);
  const cause = subCause && causes.find((c) => c.id === subCause.causeId);
  return [cause, subCause];
};

export const denmStatuses = (denm: Denm) => {
  const disabled = denm.disabled && new Date(denm.expiresAt) > new Date();
  const isDenmActive = disabled ? false : isFuture(new Date(denm.expiresAt));
  return {
    isDenmActive,
    disabled,
  };
};

interface TimeUnits {
  seconds?: number;
  minutes?: number;
  hours?: number;
}

export const convertToMilliseconds = ({
  seconds = 0,
  minutes = 0,
  hours = 0,
}: TimeUnits): number => {
  const secondsInMilliseconds = seconds * 1000;
  const minutesInMilliseconds = minutes * 60 * 1000;
  const hoursInMilliseconds = hours * 60 * 60 * 1000;

  return secondsInMilliseconds + minutesInMilliseconds + hoursInMilliseconds;
};

export const convertFromMilliseconds = (
  milliseconds: number,
): {
  validityPeriod: number;
  periodUnit: string;
} => {
  const hours = Math.floor(milliseconds / (60 * 60 * 1000));
  milliseconds %= 60 * 60 * 1000;
  const minutes = Math.floor(milliseconds / (60 * 1000));
  milliseconds %= 60 * 1000;
  const seconds = Math.floor(milliseconds / 1000);

  if (hours > 0) {
    return { validityPeriod: hours, periodUnit: 'hours' };
  } else if (minutes > 0) {
    return { validityPeriod: minutes, periodUnit: 'minutes' };
  } else {
    return { validityPeriod: seconds, periodUnit: 'seconds' };
  }
};

export const checkValidityPeriod = (validityPeriod: number, periodUnit: string): number => {
  const limits = {
    hours: 24,
    minutes: 1440,
    seconds: 86400,
  };

  return validityPeriod > limits[periodUnit] ? limits[periodUnit] : validityPeriod;
};

export const useDenmsRequest = ({
  getAllData = false,
  currentPage,
  itemsPerPage,
}: {
  getAllData?: boolean;
  currentPage?: number;
  itemsPerPage?: number;
}) => {
  const dispatch = useDispatch();
  const denmsStatus = useSelector(denmFilterStatusSelector);
  const selectedRoadSegmentIds = useSelector(selectedRoadSegmentIdsSelector);
  const denmsSources = useSelector(denmFilterSourceSelector);
  const denmsDate = useSelector(denmFilterDateSelector);
  const densmCause = useSelector(denmFilterCauseSelector);
  const denmsSubCause = useSelector(denmFilterSubCauseSelector);
  const denmsUpdatedFrom = useSelector(denmFilterUpdatedFromSelector);
  const denmsUpdatedTo = useSelector(denmFilterUpdatedToSelector);
  const denmsSincroDispatchable = useSelector(denmFilterSincroDisaptchableSelector);
  const denmsExcludeCauseAndSubcause = useSelector(denmFilterExcludeCauseAndSubcauseSelector);

  const dispatchDenmsRequest = (roadSegmentIds?: string[]) => {
    roadSegmentIds = roadSegmentIds?.length > 0 ? roadSegmentIds : selectedRoadSegmentIds;
    dispatch(
      denmsReducer.actions.denmsRequest({
        status: denmsStatus,
        roadSegmentIds: roadSegmentIds,
        sources: denmsSources,
        date: denmsDate,
        causeId: densmCause,
        subCauseId: denmsSubCause,
        updatedFrom: denmsUpdatedFrom,
        updatedTo: denmsUpdatedTo,
        sincroDispatchable: denmsSincroDispatchable,
        excludeCauseAndSubcause: denmsExcludeCauseAndSubcause,
        getAllData,
        currentPage,
        itemsPerPage,
      }),
    );
  };

  return dispatchDenmsRequest;
};

interface useDenmsOptionalCausesProps {
  optionalCauseCode: number;
  optionalSubCauseCode: number;
}

export const useDenmsOptionalCauses = ({
  optionalCauseCode,
  optionalSubCauseCode,
}: useDenmsOptionalCausesProps) => {
  const dispatch = useDispatch();
  const denmCauses = useSelector(causesSelector);
  const denmSubCausesOptional = useSelector(subCausesByCauseOptionalSelector);

  useEffect(() => {
    const cause = denmCauses.find((cause) => cause.code === optionalCauseCode);

    if (cause) {
      dispatch(denmFormReducer.actions.optionalCauseChanged(cause.id));
      dispatch(denmsReducer.actions.optionalSubCausesByCauseRequest(cause.id));
    }
  }, []);

  useEffect(() => {
    if (denmSubCausesOptional.length > 0 && optionalSubCauseCode) {
      const optionalSubCause = denmSubCausesOptional.find(
        (subCause) => subCause.code === optionalSubCauseCode,
      );
      if (optionalSubCause) {
        dispatch(denmFormReducer.actions.optionalSubCauseChanged(optionalSubCause.id));
      }
    }
  }, [denmSubCausesOptional, optionalSubCauseCode]);

  return () => {};
};

export function useDenmsForRoadSegment(roadSegmentIds: string[]) {
  const memoizedSelector = useMemo(
    () => denmsSelectorForSelectedRoadSegment(roadSegmentIds),
    [roadSegmentIds],
  );
  return useSelector(memoizedSelector);
}

export const adverseWeatherConditions = [
  'Adverse Weather Condition - Extreme Weather Condition',
  'Adverse Weather Condition - Visibility',
  'Adverse Weather Condition - Precipitation',
];

export const awaressDistanceMap = {
  [RelevanceDistance.lessThan50m]: 50,
  [RelevanceDistance.lessThan100m]: 100,
  [RelevanceDistance.lessThan200m]: 200,
  [RelevanceDistance.lessThan500m]: 500,
  [RelevanceDistance.lessThan1000m]: 1000,
  [RelevanceDistance.lessThan5km]: 5000,
  [RelevanceDistance.lessThan10km]: 10000,
};

export default { useCause };
